import { createFromIconfontCN } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { HeaderTabs } from './headerTabs';
import { HeaderUserInfo } from './headerUserInfo';
import { Home } from './home';
import type { Menudata } from './interface';
import styles from './layoutMenu.less';

const { Content, Sider, Header } = Layout;
const MyIcon = createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/c/font_4704590_26mid4373vg.js' });
@inject('layoutStore')
@observer
export class LayoutMenu extends React.Component<any> {
  componentWillUnmount() {
    this.props.layoutStore?.resizeDom?.dispose();
  }

  public openNewPage = (child: Partial<{ url: string; name: string }>): JSX.Element => {
    const { immutableStyle, getActiveSubMenu, srcParams } = this.props.layoutStore;
    const commonStyle = {
      fontSize: `${immutableStyle.itemHeight}px`,
      lineHeight: `${immutableStyle.itemHeight}px`,
    };

    const specialUrls: string[] = [
      '/api/tms/cloud/waybill/kdn/monitoring/statistics',
      '/api/tms/cloud/waybill/kdn/login/kdaas',
    ];
    return child.url.startsWith('http') || specialUrls.includes(child.url) ? (
      <a
        className={styles.rootSubMenuBlockItem}
        href={child.url}
        rel="noreferrer"
        style={{
          color: '#253041',
          ...commonStyle,
        }}
        target="_blank"
      >
        {child.name}
      </a>
    ) : (
      <div
        className={styles.rootSubMenuBlockItem}
        onClick={getActiveSubMenu.bind(this, child, srcParams)}
        style={commonStyle}
      >
        {child.name}
      </div>
    );
  };

  render(): JSX.Element {
    const { userInfoLeft, userInfoRight, defaultDashboard, logoImg } = this.props;
    const {
      menuData,
      activeMenuId,
      activeTabKey,
      tabList,
      handleToggleSubMenu,
      showSubMenu,
      getActiveSubMenu,
      togglePanel,
      immutableStyle,
      project,
      isMenuItemIcon,
    } = this.props.layoutStore;

    const result: Menudata = menuData.filter((item) => item.id === activeMenuId)[0];
    const subMenuList = result ? result.children : [[]];
    const layoutWidth = subMenuList.length * immutableStyle.blockWidth;

    const menuItems: MenuProps['items'] = menuData.map((item) => ({
      label: (
        <div
          className={`${styles.menuItem} ${item.id === activeMenuId && styles.selected}`}
          key={item.id}
          onClick={() => {
            togglePanel(true);
            handleToggleSubMenu(true, item.id);
          }}
          onMouseEnter={() => {
            handleToggleSubMenu(true, item.id);
          }}
          style={!isMenuItemIcon ? { height: 40 } : { height: 60 }}
        >
          {item.icon ? (
            <MyIcon
              type={item.icon}
              className={styles.icon}
            />
          ) : undefined}
          <span className={styles.menuName}>{item.name}</span>
        </div>
      ),
      key: item.id,
    }));
    return (
      <div className={styles.rootLayout}>
        <Layout hasSider={true}>
          <Sider
            className={styles.rootLayoutSider}
            id="rootSider"
            width="60"
          >
            {logoImg ? (
              <div className={styles.logoImg}>{logoImg}</div>
            ) : (
              <div
                className={styles.rootLayoutLogo}
                onClick={getActiveSubMenu.bind(this, tabList[0])}
              >
                {project.name}
              </div>
            )}
            <Menu
              className={styles.menu}
              id="menuLayout"
              items={menuItems}
              mode="inline"
              theme="dark"
            >
              {/* {menuData.map((item) => (
                <Menu.Item
                  className={`${styles.menuItem} ${item.id === activeMenuId && styles.selected}`}
                  key={item.id}
                  onClick={() => {
                    togglePanel(true);
                    handleToggleSubMenu(true, item.id);
                  }}
                  onMouseEnter={() => {
                    handleToggleSubMenu(true, item.id);
                  }}
                  style={!isMenuItemIcon ? { height: 40 } : undefined}
                >
                  <img
                    id={styles.menuIcon}
                    src={`https://front.ejingling.cn/pc/ts/egenie-common/menu/${item.icon}`}
                    style={!isMenuItemIcon ? { display: 'none' } : undefined}
                  />
                  <span className={styles.menuName}>
                    {item.name}
                  </span>
                </Menu.Item>
              ))} */}
            </Menu>
          </Sider>
          <Layout className={styles.layoutRight}>
            <Header className={styles.layoutHeader}>
              <HeaderTabs />
              <HeaderUserInfo
                styles={styles}
                userInfoLeft={userInfoLeft}
                userInfoRight={userInfoRight}
              />
            </Header>
            <Content>
              <div
                className={styles.layoutIframe}
                id="layoutIframe"
              >
                <Home defaultDashboard={defaultDashboard} />
                {tabList
                  ?.filter((item) => item.open)
                  ?.map((item, index) => (
                    <iframe
                      className={styles.layoutIframe}
                      frameBorder={0}
                      id={item.id}
                      key={item.id}
                      src={item.url}
                      style={{ display: item.id == activeTabKey && item.id !== 0 ? 'block' : 'none' }}
                      title={item.name}
                    />
                  ))}
              </div>
            </Content>
          </Layout>
          <div
            className={styles.rootSubMenu}
            style={{ visibility: showSubMenu ? 'visible' : 'hidden' }}
          >
            <div
              className={styles.rootSubMenuContent}
              style={{
                maxWidth: showSubMenu ? 900 : 0,
                width: layoutWidth,
              }}
            >
              {subMenuList.map((list, index) => (
                <div
                  className={styles.rootSubMenuLine}
                  key={`${String(index)}_index_`}
                  style={{ paddingTop: immutableStyle.lineTop }}
                >
                  {list?.map((item) => (
                    <div
                      className={styles.rootSubMenuBlock}
                      key={String(item.id)}
                      style={{ width: immutableStyle.blockWidth }}
                    >
                      <div
                        className={styles.rootSubMenuBlockTitle}
                        style={{
                          marginBottom: `${immutableStyle.titleMargin}px`,
                          fontSize: `${immutableStyle.titleHeight}px`,
                          lineHeight: `${immutableStyle.titleHeight}px`,
                        }}
                      >
                        {item.name}
                      </div>
                      {item.children.map((child) => (
                        <div
                          className={styles.rootSubMenuBlockList}
                          key={child.id}
                          style={{ marginBottom: `${immutableStyle.itemMargin}px` }}
                        >
                          <div className={`${styles.rootSubMenuBlockIcon} icon-${child.icon}`} />
                          {this.openNewPage(child)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div
              className={styles.rootSubMenuMask}
              onMouseEnter={handleToggleSubMenu.bind(this, false, null)}
              style={{ opacity: showSubMenu ? 0.5 : 0 }}
            />
          </div>
        </Layout>
      </div>
    );
  }
}
