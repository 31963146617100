import { requireSource } from '@/utils';
import React from 'react';
import { LayoutGuide, LayoutStore } from '../../compnents/layout';
import styles from './index.less';

// 导航 示例
export default class MenuPage extends React.Component {
  constructor(prop) {
    super(prop);
    this.layoutStore = new LayoutStore({
      project: {
        name: '',
        value: 'egenie-erp-home',
      },
    });
    this.layoutStore.handleInit();
  }

  public layoutStore: LayoutStore;

  componentDidMount(): void {}

  public getLogoImg = (): JSX.Element => (
    <div className={`${styles.pageTitle} bg-[#3A6EF8]`}>
      <img
        alt="加载失败"
        className={'w-[34px] h-[42px] mt-3 mb-2.5 '}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={requireSource('/common/logoIcon.png')}
      />
    </div>
  );

  render(): JSX.Element {
    return (
      <div style={{ position: 'relative' }}>
        <LayoutGuide
          defaultDashboard={
            <div className={styles.homePage}>
              <span>聘播播后台管理系统</span>
            </div>
          }
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={this.layoutStore}
        />
      </div>
    );
  }
}
