import { Provider } from 'mobx-react';
import type React from 'react';
import type { Opera } from './interface';
import { LayoutMenu } from './layoutMenu';
import type { ILayoutStore } from './layoutStore';

export interface Props {
  readonly children?: React.ReactNode;

  /**
   * 状态模型
   */
  readonly store: ILayoutStore;

  /**
   * 扩展头部左侧
   */
  readonly userInfoLeft?: React.ReactNode;

  /**
   * 扩展头部右侧下拉列表
   */
  readonly userInfoRight?: Opera[];

  /**
   * 自定义首页内容
   */
  readonly defaultDashboard?: React.ReactNode;

  /**
   * false首页则展示空白页. 设置defaultDashboard即可，此属性暂时不用了
   */
  readonly haveDashboard?: boolean;

  /**
   * log图片
   */
  readonly logoImg?: React.ReactNode;
}
export const LayoutGuide: React.FC<Props> = (props: Props) => (
  <Provider layoutStore={props.store}>
    <LayoutMenu
      defaultDashboard={props.defaultDashboard}
      haveDashboard={props.haveDashboard}
      logoImg={props.logoImg}
      userInfoLeft={props.userInfoLeft}
      userInfoRight={props.userInfoRight || []}
    />
  </Provider>
);
export * from './layoutStore';
