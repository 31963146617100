import { CloseCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { Dropdown, Tabs } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import styles from './layoutMenu.less';
import type { LayoutStore } from './layoutStore';

@inject('layoutStore')
@observer
export class HeaderTabs extends React.Component<{ layoutStore?: LayoutStore }> {
  componentDidMount() {
    /**
     * 禁止home项目里面使用浏览器默认右键功能
     * @param ev
     */
    document.oncontextmenu = (ev: any) => {
      ev = ev || window.event;
      return false;
    };
  }

  render() {
    const { handleTabRemove, tabList, activeTabKey, isMenuExists, handleTabChange, onFixedClick } =
      this.props.layoutStore;
    const items = tabList.map((item, index) => ({
      label: (
        <span className={styles.tabContent}>
          <span className={`${styles.tabIcon} icon-${item.icon}`} />
          <span className={styles.headerTabItem}>{item.name}</span>
        </span>
      ),
      key: String(item.id),
      closable: index !== 0,
    }));
    return (
      <Tabs
        activeKey={String(activeTabKey)}
        className={styles.headerTabs}
        hideAdd={true}
        id={styles.headerTabs}
        items={items}
        onChange={handleTabChange}
        onEdit={handleTabRemove}
        tabBarGutter={0}
        type="editable-card"
      />
    );
  }
}
