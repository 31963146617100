class ResizeHelper {
  public ele: HTMLDivElement;

  public resizeObject: HTMLObjectElement;

  public resizeWindow: Window;

  constructor(ele: HTMLDivElement) {
    this.ele = ele;
    this.ele.style.position = 'relative';
    this.resizeObject = this.createObject();
    this.resizeWindow = this.resizeObject?.contentDocument?.defaultView;
  }

  public createObject() {
    const obj = document.createElement('object');
    obj.setAttribute(
      'style',
      'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;',
    );
    obj.type = 'text/html';
    obj.data = 'about:blank';
    this.ele.appendChild(obj);
    return obj;
  }

  public debounce = (fn, wait: number) => {
    let timer = null;
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(fn, wait);
    };
  };

  public onResize(fn: (...args: any) => void) {
    this.resizeWindow?.addEventListener('resize', this.debounce(fn, 500));
  }

  public offResize(fn: (...args: any) => void) {
    try {
      this.resizeWindow?.removeEventListener('resize', fn);
    } catch (e) {
      console.warn(e);
    }
  }

  public dispose() {
    try {
      this.resizeObject.remove();
      this.resizeWindow = null;
      this.ele = null;
      this.resizeObject = null;
    } catch (e) {
      console.warn(e);
    }
  }
}

export default ResizeHelper;
